
.candidate-overview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.candidate-overview-up-arrow{
    height: 7px;
    width: 11px;
    border-radius: 2px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);;
    background-color:  green;
}
.candidate-overview-down-arrow{
    height: 7px;
    width: 11px;
    border-radius: 2px;
    transform: rotate(180deg);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);;
    background-color:  red;
}

.candidate-overview-to-do-section {
    background: white;
    width: 250px;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #D1D5DB;
}

.candidate-overview-to-do-section h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.candidate-overview-to-do-section ul {
    list-style: none;
    padding: 0;
}

.candidate-overview-to-do-section li {
    font-size: 16px;
    color: #6B7280;
    padding: 5px 0;
}


.candidate-overview-status-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    flex: 1;
}


.candidate-overview-status-card {
    width: 85%;
    height: 100px;
    background: #ffffff;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid #E5E7EB;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.candidate-overview-status-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.candidate-overview-status-header span {
    font-size: 16px;
    font-weight: 400;
    color: #374151;
}

.candidate-overview-status-card-icons {
    width: 20px;
    height: 20px;
}

.candidate-overview-status-card h2 {
    font-size: 24px;
    font-weight: bold;
    color: #111827;
    margin: 5px 0;
}

.candidate-overview-status-card p {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
}


.candidate-overview-positive {
    color: #10B981;
    font-weight: 600;
}

.candidate-overview-negative {
    color: #EF4444;
    font-weight: 600;
}


.candidate-overview-activity-log {
    width: 100%;
    background: #ffffff;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #D1D5DB;
}

.candidate-overview-activity-log h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.candidate-overview-activity-log ul {
    list-style: none;
    padding: 0;
}

.candidate-overview-activity-log li {
    font-size: 14px;
    padding: 15px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #374151;
}

.activity-icon-container::after{
    content: "";
    width: 1px;
    height: 34px;
    translate: -15px 33px 0;
    position: absolute;
    background-color:#D7D7D7;
}

.activity-icon{
    translate: 1.5px 1px 0;
}
.activity-icon-bg{
    /* display: flex; */
    /* flex-direction: row; */
    /* align-items: center;
    justify-content: center; */
    width: fit-content;
    padding: 3px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 30px;
    height: fit-content;
    /* width: 20px; */
    background-color: grey;
}


@media (max-width: 480px) {
    .candidate-overview-status-cards {
        grid-template-columns: 1fr;
    }

    .candidate-overview-status-card {
        padding: 12px;
    }

    .candidate-overview-status-card h2 {
        font-size: 18px;
    }

    .candidate-overview-activity-log {
        width: 82%;
    }

    .candidate-overview-activity-log li {
        flex-direction: column;
        align-items: flex-start;
        font-size: 12px;
    }
}
