.create-job-container {
  padding: 20px;
  border-radius: 8px; 
   /* margin: 20px 2%; */
   background-color: white;
 
}

.dynamic-input-container {
  margin-bottom: 20px;
 
}
.select-template-editbtn{
background-color: #007bff;
padding:6px 20px;
border: none;
color: white;
}

.select-template-message {
  font-size: 16px;
  color: #ff4d4d;
  font-weight: bold;
  margin-top: 20px;
}
.create-job-button {
  /* display: inline-block; */
  background-color: #007bff; 
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 35px;
  height: 3%;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.defaulttemplate-createnew{
  background-color: #ebf6ff;
  border: 1px solid #007bff;
  padding: 10px 28px;
  border-radius: 8px;
  cursor: pointer;
}
/* .create-job-button:hover {
  background-color:green;
} */
.select-template-label{
  /* margin-left: 2%; */
  margin-right: 10px;
}
.create-job-templateSelection-container{
  margin-right: 5%;
  /* background: red; */
}
.create-job-header-container-oop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  background-color: white;
  border-bottom: 1px solid #ddd;
  /* box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15); */
  margin: 0 !important;
  /* background-color: red; */
  margin-bottom: 2%;
}

.create-job-breadcrumb-job-opening{
  text-decoration: none;
  color: #666;
}

.create-job-breadcrumb {
  font-size: 16px;
  color: #666;
  display: flex;
  align-items: center;
}

.create-job-chevron {
  margin: 0 1rem;
  color: #666;
 
  
}

.create-job-breadcrumb-link {
  color: #007bff;
  font-weight: 500;
  cursor: pointer;
}

.create-job-breadcrumb-link:hover {
  text-decoration: underline;
}
.select-template-editbtn{
  padding: 8px 25px;
  background-color: #ebf6ff;
  border: 1px solid #007bff;
  color: black;

}
.create-job-menu {
  position: absolute;
  top: 20%;
  right: 10px;
  width: 200px;
  background: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  display: none;
  flex-direction: column;
  z-index: 100;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.create-job-menu.open {
  display: flex;
  opacity: 1;
  transform: translateY(0);
}

.create-job-menu-content {
  padding: 10px 0;
}

.create-job-menu-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.create-job-menu-content li {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.create-job-menu-content li a {
  text-decoration: none;
  color: #333;
  display: block;
  font-size: 14px;
}

.create-job-menu-content li:hover {
  background: #f1f1f1;
}