.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
width: 100vw;
margin: auto;
}

.signup-form-container {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 8px 0 0 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  width: 35%; 
  

}

.signup-image-container {
  width: 38%;  
}

.signup-image {
  width: 100%;
  height: auto;
 
}

.signup-heading {
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  color: #333;
  font-weight: 600;
}

.form-row {
  display: flex;
  justify-content: space-between;
  column-gap: 0.5rem;

  /* margin-bottom: 1rem; */
  /* background-color: red; */
}

.signup-form-group {
  width: 100%;
 
}

.half-width {
  flex: 1;
  margin: 0;
}

/* .custom-input {
  margin-bottom: 2rem;

} */

.checkbox-container {
  display: flex;
  align-items: start;
  margin: 0;
  padding: 0;
}

.sign-up-checkbox-label {
  font-size: 0.8rem;
  color:#555;
  width: fit-content;
  
 }

.terms-policy {
  font-size: 0.8rem;
  color: #555;
  text-align: center;
  margin-bottom: 0.5rem;
}

.terms-policy a {
  color: #007bff;
  text-decoration: none;
}

.terms-policy a:hover {
  text-decoration: underline;
}
.captcha-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0px;
}
.signUpbtn {
  width: 100%;
  padding: 0.5rem;
  background-color:#0056b3;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-button:hover {
  background-color: #0056b3;
}

.login-link {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #555;
}

.login-link a {
  color: #007bff;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}


.error-message {
  color: #ff0000;
  font-size: 0.875rem;
  display: none; 
  margin: 0; 
}

.error-message:empty {
  display: none;
}

.error-message:not(:empty) {
  display: block; 
  margin-top: 0.25rem; 
}

.signup-form-group input {
  padding: 0.75rem;
  width: 100%;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

/* .form-group input:focus {
  border-color: #FFFF8F;
  outline: none;
} */

.signup-form-group input.error {
  border-color: #ff0000;
}

.signup-form-group label {
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
  display: block;
}

@media (max-width: 768px) {
  .signup-form-container, .signup-image-container {
    width: 100%;
  }
  .form-row {
    flex-direction: column;
    gap: 1rem;
  }
  .half-width {
    width: 100%;
  }
  .signup-image-container{
    display: none;

  }
}