
.job-detail-header-container-oop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 24px;
    background-color: white;
    border-bottom: 1px solid #ddd;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
    margin: 0 !important;
  
  
  }
  
  .breadcrumb-job-opening{
    text-decoration: none;
    color: #666;
  }
  
  .breadcrumb {
    font-size: small;
    color: #666;
    display: flex;
    align-items: center;
  }
  
  .chevron {
    margin: 0 1rem;
    color: #666;
   
    
  }
  
  .breadcrumb-link {
    color: #007bff;
    font-weight: 500;
    cursor: pointer;
  }
  
  .breadcrumb-link:hover {
    text-decoration: underline;
  }