.job-detail-main-container{
  display: flex;
  flex-direction: column;
  width: 95%;
  box-sizing: border-box;
}

.job-detail-container {
  width: 100%;
  margin: auto;
  padding-bottom: 4rem;
  margin: 2rem;
 margin: 2rem 1rem 2rem 1.5rem;
}

.edit-button-container {
  display: flex;
  justify-content: start;
  align-items: center;
}

.viewJobDetail-heading {
  padding-right: 2rem;
  color: black;
}

.job-detail-editbtn {
  width: fit-content;
  background-color: #1e88e5;
  height: fit-content;
  outline: none;
  padding: 0.4rem 1rem;
  border: 1px solid #007bff;
  border-radius: 5px;
  color:white;
}

.edit-iconImage {
  padding-left: 0.8rem;
  height: 12px;
}

.job-section {
  margin-bottom: 15px;
  background-color: white;

}

.job-section-title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: white;
  cursor: pointer;
}

.accordion-icon {
  font-size: 16px;
  transition: transform 0.3s ease-in-out;
}

.accordion-icon.open {
  transform: rotate(180deg);
}

.job-detail-table-wrapper {
  border: 1px solid #1e88e5;
  border-radius: 5px;
  overflow-x: hidden; 
  word-break: break-word; /* Ensures content wraps */
  flex-wrap: wrap;
}
.job-detail-table {
  width: 100%;

  padding: 0px 0;
 border-collapse: collapse;
  
}

.job-detail-table-row:nth-child(even) {
  background-color: #ebf6ff;
  
}

.job-detail-table-row:nth-child(odd) {
  background-color: #ffffff;
  }

.job-detail-label {
  font-weight:550;
  width: 30%;
  padding: 0 10px;
}

.job-detail-value {
  padding: 0 10px;
}

.job-detail-html-content {
  padding: 5px;
  border-radius: 4px;
  word-break: break-word; /* Ensures words break and wrap properly */
  overflow: hidden; 
  white-space: normal; /* Allows text wrapping */
  display: block;
  width: 100%;
}


.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
  padding: 0 10px;
}

.accordion-content.open {
  max-height: 1000px;
  padding: 10px;
}
.job-detail-label-foreditor{
  font-weight: bold;
  padding: 20px 10px;
}
.job-detail-bottom-container{
  background-color: #F5F7FA;
 padding: 1rem;
 display: flex;
 justify-content: center;
 position: fixed; 
 bottom: 0;
 left: 0;
 width: 100%; 
 z-index: 5;

}
.job-detail-backtolist{
  color: black;
  background-color: transparent;
  margin-right: 0.8rem;
}


@media (max-width: 768px) {
  .job-detail-container {
    width: 95%; 
    margin: 1rem auto;
  }

  .job-detail-bottom-container {
    padding: 0.8rem;
  }

}

















