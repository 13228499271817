.forgotpage-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
    gap: 20px;
    width: 100vw;
    margin: auto;
  }
  .forgotpage-image-container {
    width: 35%;  
  }
  
  .login-image {
    width: 100%;
    height: auto;
  }
  .forgotpage-form-container {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 8px 0 0 8px;
    width: 35%;  
  }
  
  
  
  .forgotpassword-heading {
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    color: #333;
    font-weight: 600;
  }
  .forgot-para{
    font-size: 14px;
  }
  
  .login-form-group {
    width: 93%;
    margin-bottom: 1rem;
  
    
    
  }
  
  .forgotpage-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #0056b3;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .forgotpage-link {
    text-align: center;
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #555;
  }
  
  .forgotpage-link a {
    color: #007bff;
    text-decoration: none;
  }
  
  .forgotpage-link a:hover {
    text-decoration: underline;
  }