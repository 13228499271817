.loader-container {
    display: flex;
    top: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    background-color: white;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    position: absolute;
    z-index: 1000; 
  }


.loader {
    width: 5em;
    height: 5em;
  }
  
  .loader__eye1,
    .loader__eye2,
    .loader__mouth1,
    .loader__mouth2 {
    animation: eye1 3s ease-in-out infinite;
  }
  
  .loader__eye1,
    .loader__eye2 {
    transform-origin: 64px 64px;
  }
  
  .loader__eye2 {
    animation-name: eye2;
  }
  
  .loader__mouth1 {
    animation-name: mouth1;
  }
  
  .loader__mouth2 {
    animation-name: mouth2;
    visibility: hidden;
  }
  
  @media (prefers-color-scheme: dark) {
    :root {
      --bg: hsl(var(--hue), 90%, 10%);
      --fg: hsl(var(--hue), 90%, 90%);
    }
  }
  
  @keyframes eye1 {
    from {
      transform: rotate(-260deg) translate(0, -56px);
    }
  
    50%,
      60% {
      animation-timing-function: cubic-bezier(0.17, 0, 0.58, 1);
      transform: rotate(-40deg) translate(0, -56px) scale(1);
    }
  
    to {
      transform: rotate(225deg) translate(0, -56px) scale(0.35);
    }
  }
  
  @keyframes eye2 {
    from {
      transform: rotate(-260deg) translate(0, -56px);
    }
  
    50% {
      transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1);
    }
  
    52.5% {
      transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1, 0);
    }
  
    55%,
      70% {
      animation-timing-function: cubic-bezier(0, 0, 0.28, 1);
      transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1);
    }
  
    to {
      transform: rotate(150deg) translate(0, -56px) scale(0.4);
    }
  }
  
  @keyframes eyeBlink {
    from,
      25%,
      75%,
      to {
      transform: scaleY(1);
    }
  
    50% {
      transform: scaleY(0);
    }
  }
  
  @keyframes mouth1 {
    from {
      animation-timing-function: ease-in;
      stroke-dasharray: 0 351.86;
      stroke-dashoffset: 0;
    }
  
    25% {
      animation-timing-function: ease-out;
      stroke-dasharray: 175.93 351.86;
      stroke-dashoffset: 0;
    }
  
    50% {
      animation-timing-function: steps(1, start);
      stroke-dasharray: 175.93 351.86;
      stroke-dashoffset: -175.93;
      visibility: visible;
    }
  
    75%,
      to {
      visibility: hidden;
    }
  }
  
  @keyframes mouth2 {
    from {
      animation-timing-function: steps(1, end);
      visibility: hidden;
    }
  
    50% {
      animation-timing-function: ease-in-out;
      visibility: visible;
      stroke-dashoffset: 0;
    }
  
    to {
      stroke-dashoffset: -351.86;
    }
  }