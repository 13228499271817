/* Main Header Styling */
.ATS-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Left Container: Logo and Navigation */
.ATS-header .left-container {
  display: flex;
  align-items: center;
  gap: 3rem; /* Adjust this gap to increase space between logo and menu */
}

/* Logo Styling */
.ATS-header .logo {
  height: 2.5rem;
}

/* Navigation Menu */
.ATS-header nav {
  display: flex;
}

.ATS-header nav ul {
  display: flex;
  gap: 1.5rem;
  list-style: none;
  padding: 0;
  margin: 0;
}

.ATS-header nav ul li a {
  color: var(--backgroundHeader);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.ATS-header nav ul li a:hover {
  color: #2563eb;
}

/* Right Container: Buttons */
.ATS-header .right-container {
  display: flex;
  gap: 1rem;
}

/* Button Styling */
.ATS-header .button {
  padding: 0.5rem 1rem;
  background-color: #2c3e50;
  color: white;
  border-radius: 0.375rem;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.ATS-header .button:hover {
  background-color: transparent;
  border: 1px solid #2c3e50;
  color: #2c3e50;
}

/* Hamburger Menu - Hidden by Default */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  transition: transform 0.3s ease;
}

/* Open State for Hamburger Menu */
.hamburger .bar.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger .bar.open:nth-child(2) {
  opacity: 0;
}

.hamburger .bar.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Mobile Navigation - Initially Hidden */
@media screen and (max-width: 768px) {
  .ATS-header {
    padding: 1rem;
  }

  /* Left Container */
  .ATS-header .left-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  

  /* Right Container: Hide Desktop Buttons */
  .ATS-header .right-container {
    display: none;
  }

  /* Show Hamburger Menu */
  .ATS-header .hamburger {
    display: flex;
  }
  .hamburger {
    margin-left: auto; /* Pushes it to the right */
  }
  
  /* Mobile Navigation */
  .ATS-header nav {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    display: none;
    flex-direction: column;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem 0;
  }

  .ATS-header nav.active {
    display: flex;
  }

  .ATS-header nav ul {
    flex-direction: column;
    gap: 1rem;
  }

  .ATS-header nav ul li {
    padding: 0rem 0;
  }

  /* Access Button Visible in Mobile */
  .ATS-header nav .button {
    display: block;
    margin-top: 1rem;
    text-align: center;
  }
 
}
