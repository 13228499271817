.footer {
  color: var(--fullBlack);
 
  border-top: 1px solid #E8E8E8;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: auto;
}

.footer-logo-section {
  flex: 1;
}
.footer-logo-section p{
  margin-bottom: 2%;
}

.footer-logo {
  font-size: 24px;
  font-weight: bold;
}

.footer-links {
  /* background-color: red; */
  margin-right: 8%;
  display: flex;
  flex: 2;
  justify-content: space-around;
}

.footer-column h4 {
  color: #00aadc;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin: 5px 0;
}
.footer-column a{
  text-decoration: none;
  color: black;
}

.newsletter-content {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.footer-newsletter {
  text-align: left; 
}

.newsletter-input {
  display: flex;
  /* margin-top: 10px; */
}

.newsletter-input input {
  padding: 10px;
  width: 250px;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 4px;
  flex: 1;
}

.newsletter-input button {
  background-color: #00aadc;
  color: #fff;
  border: none;
  padding: 10px 15px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 4px;
}

.social-icons-container {
  display: flex;
  justify-content: flex-start; /* Align icons to the left */
  margin-top: 10px;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons svg {
  margin-right: 10px;
  cursor: pointer;
  font-size: 20px;
}

.footer-bottom {
  background-color: #0073e6;
  color: #fff;
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-bottom-links a {
  color: #fff;
  margin-left: 20px;
  text-decoration: none;
}
.footer-logo-image{
 width:8vw;
 height: 8vh;
 margin-top: 2rem;
}


/* For screens between 1440px and 3000px (Large Screens & 4K displays) */
@media (min-width: 1440px) {
  .footer-container {
    max-width: 1600px;
    padding: 50px;
  }
  .footer-logo {
    font-size: 28px;
  }
  .footer-links {
    gap: 50px;
  }
  .newsletter-input input {
    width: 300px;
  }
  .social-icons svg {
    font-size: 24px;
  }
}


@media (max-width: 1439px) {
  .footer-container {
    flex-direction: row;
    padding: 40px;
  }
  .footer-links {
    gap: 30px;
  }
  .newsletter-input input {
    width: 250px;
  }
}

/* For screens between 768px and 1023px (Tablets & Small Laptops) */
@media (max-width: 1023px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-right: 0;
  }
  .newsletter-content {
    align-items: center;
  }
  .newsletter-input input {
    width: 200px;
  }
  .social-icons {
    justify-content: center;
  }
}

/* For screens between 480px and 767px (Large Phones & Small Tablets) */
@media (max-width: 767px) {
  .footer-container {
    padding: 30px;
  }
  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .newsletter-input input {
    width: 180px;
  }
  .footer-bottom {
    flex-direction: column;
    padding: 10px;
  }
}

/* For screens up to 479px (Small Phones) */
@media (max-width: 479px) {
  .footer-container {
    flex-direction: column;
    align-items:start;
    text-align: start;
    padding: 20px;
  }

  .footer-logo-section {
    text-align: start;
    margin-bottom: 15px;
  }

  .footer-links {
    flex-direction: column;
    align-items: start;
    text-align: start;
    width: 100%;
    gap: 10px;
  }

  .footer-column {
    width: 100%;
  }

  .newsletter-content {
    width: 100%;
    align-items: center;
  }

  .newsletter-input {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .newsletter-input input {
    width: 90%;
    margin-bottom: 10px;
  }

  .social-icons {
    justify-content: center;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
  }

  .footer-bottom-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

}

