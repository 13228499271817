

/* Container for the entire component */
.embeded-code-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 8px;

}
/* Style for the template name input */
.template-name-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 300px;
}

/* Style for each color element */
.color-elements-row {
    display: flex;
    flex-wrap: wrap; 
    gap: 15px; 
    align-items: center;
}

.color-element {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.color-element p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.color-preview {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ddd;
}

/* Style for the font change section */
.font-change-section {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.font-change-section p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}


.save-changes-button {
    width: fit-content;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #561ecb;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.save-changes-button:hover {
    background-color: #450fa3;
}