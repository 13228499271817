.main-edit-job-container{
    display: flex;
    margin: 2%;
    padding: 0;
}
.edit-create-job-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    justify-content: center;
    width: 20vw;
    padding: 0;
    height:800px;
   

}
.edit-dynamic-input-container{
    margin-top: 5%;
   
}
.edit-create-job-button {
    padding: 2px 6px;
    background-color:white;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    width: 150px;
    height: 50px;
    row-gap: 10px;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.2s ease;
    min-width: 150px;
    padding: 0;
  
}

.edit-create-job-button:hover {
    background-color: #007BFF;
    color: #fff;
    transform: scale(1.05);
   
}


.edit-create-job-title {
    color: #333;
    font-size: 16px;
}
.editJob-savebtn{
    margin-left: 2%;
    background-color:#007BFF;
    color: white;
    border:none;
    outline: none;
    padding: 7px 15px;
    border-radius: 5px;
    /* background-color: pink; */
}
.editjob-cancelbtn{
    background-color: red;
    color: white;
    margin: 2%;
    border:none;
    outline: none;
    padding: 7px 15px;
    border-radius: 5px;

}
.editjob-cancelbtn:hover{
    background-color: red;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:3;
}

.popup-content {
    background: #ddd;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-content h3 {
    margin-bottom: 10px;
    
}

.popup-content button {
    margin: 5px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007BFF;
    color: white;
}
.popup-content .popup-cancelbtn{
    background-color: red;
}
.template-name-heading{
  /* border: none;
  outline: none; */
  font-size: larger;
  font-weight: bold;
  padding:2%;
  outline: none;

}

/* whichSection.css */

.which-section-container {
    width: 100%;
 
    /* padding: 16px; */
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    
}

.which-section-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.which-section-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.which-section-list-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    transition: background 0.3s ease;
}

.which-section-list-item:hover {
    background-color: #f0f0f0;
}

.which-section-close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    font-weight: bold;
    color: #666;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.3s ease;
}

.which-section-close-btn:hover {
    color: #d9534f;
}
.each-section-styles{
    position: relative;
    border: 1px solid #007BFF;  
    border-radius: 8px;  
    padding: 16px;  
    margin-bottom: 30px;
    width: 93vw;
    background: #fff;  
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
    transition: all 0.3s ease-in-out; 
    min-height: 100px; 
   
    
}
.each-section-hover-icon {
    position: absolute;
    top: -1%;  
    left: -5px; 
    background: #007BFF;
    color: white;
  border-radius: 5px;
    
    display: none;  
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  
}
.each-section-styles:hover .each-section-hover-icon {
    display: block;
    border: 2px solid #007BFF;
}
.edit-job-section{
    display: flex;
    justify-content: space-between;
    padding:  0.5rem 1.4rem;
}
.editjob-delsection-btn{
    border: 1px solid #A4A4A4 ;
    align-items: center;
    align-self: center;
    
}
.each-feild-conatiner {
    width: 100%;  
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    min-width: 300px; /* Prevents shrinking too much */
}

/* For Grid Layout */
.each-feild-conatiner.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    width: 100vw;
}
.add-fields-container{
   
    text-align: center;
    margin: 1rem 0;
}
.add-fields-btn{
    background-color: #ebf6ff;
    border:1px solid #1e88e5;
    padding: 0.5rem 1rem;
    color: #1e88e5;
}
.add-field-btnicon{
    margin-left: 1rem;
    align-items: center;
}

/* Prevent Grid Layout if space is insufficient */
@media (max-width: 350px) {
    .each-feild-conatiner.grid {
        display: flex;  /* Fallback to flex layout */
        flex-direction: column;
    }
}

/* For Column Layout */
.each-feild-conatiner.column {
    display: flex;
    flex-direction: column;
}

/* For Row Layout */
.each-feild-conatiner.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; 
    overflow-x: auto; 
    white-space: nowrap; 
    width: 100vw; 
    padding-bottom: 5px; 
}

/* Prevent Row Layout if space is insufficient */
@media (max-width: 350px) {
    .each-feild-conatiner.row {
        flex-direction: column; /* Switch to column if space is too small */
    }
}

.add-dynamic-fields {
    background-color: red;
    padding: 6px 18px;
    border-radius: 8px;
    margin-left: 2%;
    border: 1px solid #ccc;
    font-size: 14px;
    cursor: pointer;
    appearance: none; 
    position: relative;
    z-index: 10; 
}

/* Style the dropdown arrow */
.add-dynamic-fields::after {
    content: "▼";
    font-size: 12px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}

/* Ensure dropdown always opens downward */
.add-dynamic-fields option {
    background: white;
    color: black;
}

/* Add hover effect */
.custom-dropdown:hover {
    background-color: darkred;
}
.add-section-dynamically {
    display: flex;
    align-items: center;
    justify-content: center; 
    gap: 8px; 
    background-color: #ebf6ff;
    color: black;
    padding: 8px 16px;
    border: 1px solid #007BFF;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
margin-right: 20px;
}

.add-section-dynamically:hover {
    background-color: transparent;
}

.add-section-icon-style {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.edit-create-job-header-container-oop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: white;
  border-bottom: 1px solid #ddd;
  /* box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15); */
 
 

}
.create-job-breadcrumb {
    font-size: 16px;
    color: #666;
    display: flex;
    align-items: center;
    padding-left: 20px;

   
  }
  
  .create-job-chevron {
    margin: 0 1rem;
    color: #666;
   
    
  }
  
  .create-job-breadcrumb-link {
    color: #007bff;
    font-weight: 500;
    cursor: pointer;
  }
  
  .create-job-breadcrumb-link:hover {
    text-decoration: underline;
  }
 