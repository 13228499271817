.carousel-container {
    text-align: center;
    padding: 40px;

    border-radius: 0 0 150px 0;
  }
  
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: aliceblue;
  }
  
  .highlight {
    color: #2d6cdf;
  }
  
  .useCase-btn{
    border-radius: 8px;
    margin-bottom: 1rem;
    background-color: black;
    color: white;
  }

  .usecase-image{
    margin-right: 0.5rem;
  }
/* Tabs Container - This gives the full border around all tabs */
.tabs-container {
    display: flex;
    justify-content: center;
    background: white;
    border: 2px solid #ccc; 
    border-radius: 30px; 
    padding: 5px; 
    width: fit-content;
    margin: 0 auto 20px auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
  }
  

  .tabs {
    display: flex;
    gap: 10px;
    color: black;
  }
  

  .tab-button {
    padding: 10px 20px;
    border: none;
    background: transparent;
    font-size: 16px;
    cursor: pointer;
    color: black;
    transition: all 0.3s ease;
    border-radius: 20px; 
  }
  
  /* Active Tab Styling */
  .tab-button.active {
    background-color: black;
    color: white;
    font-weight: bold;
    border-radius: 20px;
  }
  .carousel-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 20px;
   
  }
  
  .carousel-card {
    display: flex;
    align-items: center;
    background: #F5F7FA;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 75vw;
   
    height: 250px;
    transition: all 0.5s ease-in-out;
  }
  
  .card-content {
    flex: 70%;
    text-align: left;
    margin-left: 2rem;
   

  }
  
  .card-content h3 {
  
    font-size: 22px;
    margin-bottom: 50px;
  
  }
  .heading-icon {
    width: 50px; 
    height:50px;
    margin-right: 10px;
    vertical-align: middle;
  }
  
  .card-content p {
    padding-top: 2rem;
    font-size:22px;
    color:#727677;
    color: black;
   line-height: 1.5em;
  }
  
  .card-image {
    flex: 30%;
    display: flex;
    justify-content: center;
  }
  
  .card-image img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
  }
  .carousel-slider {
    width: 900px;
    display: flex;
    justify-content: center;
    animation: slide-in 0.5s ease-in-out;
  
  }
  
  @keyframes slide-in {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .nav-button {
    background-color: #0073e6;
    color: white;
    border: none;

    width:40px;
    height: 40px;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
   border-radius: 50%;
    background-color:#2C3E504A;
  
  }
  
  .nav-button.left {
    left: -3px;
  }
  
  .nav-button.right {
    right: -3px;
  }
  
 
  @media screen and (max-width: 768px) {
    .carousel-container {
      padding: 20px;
      border-radius: 0 0 50px 0; 
    }
  
    h2 {
      font-size: 20px;
    }
  
    .tabs-container {
      flex-direction: column;
      width: 90%; 
      padding: 10px;
    }
  
    .tabs {
      flex-wrap: wrap;
      justify-content: center;
      gap: 5px;
    }
  
    .tab-button {
      padding: 8px 15px;
      font-size: 14px;
    }
  
    .carousel-content {
      flex-direction: column; 
      margin-top: 10px;
    }
  
    .carousel-card {
      flex-direction: column;
      width: 90%; 
      height: auto;
      padding: 15px;
    }
  
    .card-content {
      flex: 100%;
      text-align: center;
    }
  
    .card-content h3 {
      font-size: 18px;
      margin-bottom: 15px;
    }
  
    .card-content p {
      font-size: 14px;
    }
  
    .card-image {
      flex: 100%; 
      margin-top: 15px;
    }
  
    .card-image img {
      width: 150px;
      height: 150px;
    }
  
    .carousel-slider {
      width: 100%;
    }
  
    .nav-button {
      padding: 8px;
      font-size: 14px;
    }
  
    .nav-button.left {
      left: 10px;
    }
  
    .nav-button.right {
      right: 10px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .carousel-container {
      padding: 15px;
    }
  
    h2 {
      font-size: 18px;
    }
  
    .tabs-container {
      width: 100%;
      padding: 5px;
      border-radius: 15px;
    }
  
    .tab-button {
      font-size: 12px;
      padding: 6px 10px;
    }
  
    .carousel-card {
      width: 95%;
      padding: 10px;
    }
  
    .card-content h3 {
      font-size: 16px;
    }
  
    .card-content p {
      font-size: 12px;
    }
  
    .card-image img {
      width: 120px;
      height: 120px;
    }
  
    .nav-button {
      padding: 6px;
      font-size: 12px;
    }
  }
  