
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  gap: 20px;
  width: 100vw;
  margin: auto;
}
.login-image-container {
  width: 35%;  
}

.login-image {
  width: 100%;
  height: auto;
}
.login-form-container {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 8px 0 0 8px;
  width: 35%;  
}



.login-heading {
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  color: #333;
  font-weight: 600;
}

.login-form-group {
  width: 93%;
  margin-bottom: 1rem;

  
  
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #0056b3;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.login-link {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #555;
}

.login-link a {
  color: #007bff;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

.error-message {
  color: #ff0000;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  display: flex;
  justify-content: start;
}

.remember-forgot-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

.remember-me {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #555;
}

.remember-me input {
  margin: 0;
  cursor: pointer;
}

.forgot-password {
  color: #007bff;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}


.divider-container {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 15px 0;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #ccc;
}

.divider-text {
  padding: 0 10px;
  color: #888;
  font-size: 14px;
}


.social-buttons {
  display: flex;
  gap: 10px;
}

.social-buttons button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: white;
  cursor: pointer;
}

.social-buttons img {
  width: 20px;
  margin-right: 8px;
}

@media (max-width: 768px) {
  .login-form-container, .login-image-container {
    width: 100%;
  }
  .login-image-container{
    display: none;
  }
  
}
@media (min-width:1920px){
  .login-form-group{
    width: 98%;
  }
}





