
.candidate-profile-container {
    padding: 20px;
    background-color: white;
    padding-bottom: 5rem;

}

.candidate-profile-header {
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding-bottom: 15px;    
    border-bottom: 1px solid #ddd;
}

.candidate-profile-info {
    display: flex;
    align-items: center;
    gap: 15px;
   margin-right: 2rem;
}

.candidate-profile-avatar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.candidate-profile-avatar {
    width: 80px;
    height: 80px;
    background-color: #6479AB30;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.candidate-profile-social-icons {
    font-size: 14px;
     margin-top: 5px;
}

.candidate-name {
    font-size: 18px;
    margin: 0;
}

.designation {
    font-size: 14px;
    color: #555;
    margin-top: 3px;
}
.candidate-profile-social-iconsimg{
    width: 1rem;
    height: 1rem; 
    margin-right: 1rem;
    cursor: pointer;
  
}
.candidate-noprofile-social-iconimg{
    width: 1rem;
    height: 1rem; 
    margin-right: 1rem;
}
.candidate-profile-details {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.candidate-profile-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.candidate-profile-group strong {
    color:#A1A6B4;
    font-size: 14px;
    font-weight: 400;
}

.candidate-profile-group p {
    font-size: 14px;
    color:black;
    margin: 0;
}

.candidate-profile-source-group {
    display: flex;
    gap: 15px;
    font-size: 12px;
    color: #666;
    margin-bottom: 5px;
}


.candidate-profile-source-group div {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.source-label {
    font-size: 10px;
    color: #888;
}

.source-value {
    font-size: 12px;
    color: #000;
   
}

.candidate-profile-tabs {
    display: flex;
    margin-top: 10px;
    border-bottom: 1px solid #ddd;
    
}

.candidate-profile-tab {
    padding: 10px 0px 2px;
    margin-right: 2rem;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: #555;
    transition: color 0.3s ease, border-bottom 0.3s ease;
}

.candidate-profile-tab.active {
    border-bottom: 3px solid black;
    color: black;

    /* font-weight: bold; */
}

.candidate-profile-content {
 
    background: white;
    border-radius: 5px;
    margin-top: 15px;

}
.candidate-profile-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;

}

.action-icon {
    width: 30px;
    height: 30px;
    border: 1px solid #888;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #555;
    cursor: pointer;
    transition: all 0.3s ease;
}

.action-icon:hover {
    background-color: #f0f0f0;
}

.more-options {
    font-size: 22px;
}
.action-icon img {
    width: 20px; 
    height: 20px;
    cursor: pointer;
    margin: 5px;
    transition: transform 0.2s ease-in-out;
  }
  
  .action-icon img:hover {
    transform: scale(1.1);
  }
  .candidate-profile-bottom-container {
    width: 100%;
    height: 12vh;
    background-color:#F5F7FA;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.candidate-profile-button-container {
    display: flex;
    gap: 10px;
}

.candidate-profile-button-container button{
    padding: 8px 20px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 3px;
    border:1px solid var(--primaryColor);
}

.candidate-profile-resume-previewbtn{
  

    background-color: transparent;
    color:var(--primaryColor);
   

}
.candidate-profile-edit-btn{
    background-color: var(--primaryColor);
    color:var(--fullWhite);
}
.candidate-profile-next-btn{
    background-color: transparent;
    color: black;
}
.candiidate-edit-img{
    cursor: pointer;

}

 /* .sent-email-modal {
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .sent-email-modal {
    position: fixed;
    top:0;
    right: 0;
    width: 60vw; 
    height: 100vh;
    background: white;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
    animation: slideIn 0.8s ease-in-out;
    z-index: 999999;
  
  }
  
  /* Show modal when active */
  .sent-email-modal.show {
    transform: translateX(0); 
opacity: 1;
  }
  
  
  .sent-email-container {
    background: white;
    padding-top: 0px;
    padding: 0px 0px 20px 0px;
    border-radius: 8px;
    width: 60vw;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }
  
  /* Header */
  .sent-email-header-container {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    align-items: center;
    border-bottom: 1px solid #ddd;
    background-color: var(--headerBackground);
  
  }
  
  .sent-email-header {
    font-size: 18px;
    font-weight: bold;
    color: white;
    
  }
  
  .sent-email-close {
    font-size: 20px;
    cursor: pointer;
    color: black;
    font-weight: bold;
  }
  
  /* Fields */
  .sent-email-field {
    display: flex;
    flex-direction: column;
    width: 95%;
 
  }
  
  .sent-email-label {
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  /* Input and Select Styling */
  .sent-email-input
   {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .sent-email-select{
    width: 100%;
    padding: 8px;

    border-radius: 4px;

  }
  
  /* CC & BCC Row */
  .sent-email-row {
    display: flex;
    gap: 15px;
    width: 100%;
  }
  
  .sent-email-row .sent-email-field {
    width: 50%;
  }
  

  
  /* Buttons */
  .sent-email-button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 10px;
  }
  
  .sent-email-send-button {
    background-color: #007bff;
    color: white;
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }

  
  .sent-email-cancel-button {
    color: black;
    background-color: transparent;
    padding: 8px 15px;
    border: 1px solid #007bff;
    cursor: pointer;
    border-radius: 4px;
  }
  

  
@media (max-width:575.98px) {
    .candidate-profile-header{
        display: flex;
        flex-direction: column;
    }
    
}

@media (min-width:1200px){
    .candidate-profile-details{
        gap:1rem;
        display: flex;
        flex: 1;
        justify-content: space-around;
    }
}

@media (max-width: 768px) {
   
    .candidate-profile-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }

   
    .candidate-profile-info {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-right: 0;
    }


    .candidate-profile-avatar {
        width: 70px;
        height: 70px;
        font-size: 18px;
    }


    .candidate-profile-social-icons {
        display: flex;
        justify-content: center;
    }


    .candidate-profile-details {
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }


    .candidate-profile-tabs {
        overflow-x: auto;
        white-space: nowrap;
        flex-wrap: nowrap;
        display: flex;
    }

    .candidate-profile-tab {
        flex-shrink: 0;
        padding: 8px 15px;
    }


    .candidate-profile-bottom-container {
        height: auto;
        padding: 10px 0;
    }

    .candidate-profile-button-container {
        flex-direction:row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .candidate-profile-button-container button {
        width: 25%;
        padding: 10px;
    }
}

@media (max-width: 480px) {

    .candidate-profile-avatar {
        width: 60px;
        height: 60px;
        font-size: 16px;
    }


    .candidate-name {
        font-size: 16px;
    }

    .designation {
        font-size: 12px;
    }

    .candidate-profile-group strong,
    .candidate-profile-group p {
        font-size: 12px;
    }

    .candidate-profile-actions {
        gap: 1px;
    }


    .candidate-profile-button-container {
        flex-direction: row;
        justify-content: center;
        gap: 5px;
    }

    .candidate-profile-button-container button {
        width: fit-content;
       
    }
}
