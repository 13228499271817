/* Main Testimonial Container */
.testimonial-container {
  max-width: 1200px; /* Limits overall width */
  margin: 0 auto; /* Centers the container */
  text-align: center;
  padding: 50px 20px;
  /* background-color: green; */
  background-color: var(--fullWhite);
  overflow: hidden; /* Prevents overflow */
}

/* Title Styling */
.testimonial-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #111;
}

.highlight {
  color: #007bff;
}

/* Testimonial Wrapper */
.testimonial-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 100%;
  overflow: hidden; /* Ensures content doesn't overflow */
}

/* Content that moves left/right */
.testimonial-content {
  display: flex;
  gap: 20px;
  transition: transform 0.5s ease-in-out;
}

/* Individual Testimonial Card */
.testimonial-card {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 0 0 calc(100% / 3); /* Ensure exactly 3 cards per view */
  max-width: calc(100% / 3); /* Same as flex-basis */
  transform: scale(1); /* Remove unnecessary shrinking */
  transition: all 0.5s ease-in-out;
  box-sizing: border-box; /* Ensures padding is included in width */
}

/* Active Card */
.testimonial-card.active {
  transform: scale(1);
  border: 2px solid #007bff;
}

/* Testimonial Header */
.testimonial-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.testimonial-img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: cover;
}

.testimonial-name {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.testimonial-role {
  color: #6b7280;
  margin: 0;
  font-size: 14px;
}

/* Testimonial Text */
.testimonial-text {
  font-size: 16px;
  margin-top: 10px;
  color: #333;
}

/* Navigation Arrows */
.testimonial-arrows {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}

.testimonial-arrow {
  background: #1f2937;
  color: white;
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .testimonial-content {
      flex-direction: column;
      align-items: center;
  }

  .testimonial-card {
      max-width: 90%;
      text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .testimonial-container {
      padding: 30px 15px;
  }

  .testimonial-title {
      font-size: 24px;
  }

  .testimonial-card {
      width: 95%;
      padding: 15px;
  }

  .testimonial-arrow {
      width: 35px;
      height: 35px;
      font-size: 14px;
  }

  .testimonial-text {
      font-size: 13px;
  }
}