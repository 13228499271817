
.custom-overlay-base {
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    
  }
  
  
  .custom-modal-base {
    background: white;
    width: 90%;
    max-width: 400px; 
    max-height: 80vh; 
    border-radius: 8px;
    padding: 0px 0px 10px 0px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  

  .modal-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 13px;
    font-weight:400;
    background-color:var(--headerBackground);
    color: var(--fullWhite);
 
  
  }

  .modal-close {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: var(--fullWhite);
  
  }
  

  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 0;
    max-height: 50vh;
    overflow-y: auto;
    padding: 0 20px;
  }

  .job-selection {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    padding:0;
    margin: 0;

 
  
  
  }

  .job-selection input[type="checkbox"] {
    width: 18px;
    height: 18px;
    accent-color:var(--primaryColor);
  }
  

  .modal-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    padding: 0px 20px;
  }
  

  .modal-footer .cancel-btn {
    background: var(--fullWhite);
    color: #333;
    padding: 8px 15px;
    border: 1px solid var(--primaryColor);
    border-radius: 5px;
    cursor: pointer;
  }
  

  .modal-footer .save-btn {
    background:var(--buttonBgLight);
    color:var(--primaryColor);
    padding: 8px 15px;
    border:1px solid var(--primaryColor) ;
    border-radius: 5px;
    cursor: pointer;
 
  }
  
  .modal-footer .save-btn:disabled {
    cursor: not-allowed;
  }
  