.custom-modal-base {
  background: white;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  margin: auto;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.custom-modal-base_after-open {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.3s;
}

.custom-modal-base_before-close {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.3s, transform 0.3s;
}
.custom-overlay-base {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-overlay-base_after-open {
  opacity: 1;
  transition: opacity 0.3s;
}

.custom-overlay-base_before-close {
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.custom-modal-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.custom-modal-close {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.custom-modal-input-group {
  display: flex;
  align-items: center;
  gap: 10px; 
}


.custom-modal-input {
  flex: 1; 
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 10px;

}

.custom-modal-add-button {
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.custom-modal-add-button:hover {
  background: #0056b3;
}

.custom-modal-save-button {
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  align-self: flex-start;
}

.custom-modal-add-button:hover,
.custom-modal-save-button:hover {
  background: #0056b3;
}

.custom-modal-data-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.custom-modal-data-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  background: #eceaea;
}

.custom-modal-data-text {
  margin: 0;
  font-size: 14px;
}

.custom-modal-delete-button {
  background: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.custom-modal-delete-button:hover {
  background: #b21f2d;
}
