/* 
.input-group {
    position: relative;
    margin-bottom: 20px;
    
  }
  
 
  .input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-bottom: 2px solid #ccc;
    outline: none;
    background: transparent;
    transition: border-color 0.3s ease;
  

  }
  
  .input:focus {
 
    border-bottom: 2px solid black;
  }
  
 
  .label {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 16px;
    color: #aaa;
    pointer-events: none;
    transition: all 0.3s ease;
  }
  
  .input:focus + .label,
  .input:not(:placeholder-shown) + .label {
    top: -12px;
    font-size: 14px;
    color: black;
 
  }
  .error-message {
    font-size: 12px;
    color: red;
    margin-top: 5px;
  }
   */




   .input-group {
    position: relative;
    margin-bottom: 1rem;
 
  }
  
  .input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    outline: none;
    background-color: transparent; 
  }
  
  .input:focus {
    border-color: #415f2d;
    
    
  }
  
  .label {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    font-size: 1rem;
    color: #999;
    transition: all 0.2s ease;
    pointer-events: none;
    background-color: white; 
    padding: 0 0.25rem; 
    z-index: 1; 
    
  }
  
  .input:focus + .label,
  .input:not(:placeholder-shown) + .label {
    top: -0.5rem;
    left: 0.5rem;
    font-size: 0.75rem;
    color:#415f2d;
    background-color: white; 
  }
  
  .error-message {
    color: #ff0000;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }