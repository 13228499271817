.department-container {
    padding: 20px;
 
    /* background-color: red; */
    width: 100%;
    max-width: 100%;
}

.department-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
}

.department-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.table-header {
    background-color: #f1f1f1;
    padding: 10px;
    text-align: left;
    font-weight: bold;
    border: 1px solid #ddd;
}

.table-row:hover {
    background-color: #f9f9f9;
}

.table-cell {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.no-data {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
}

.table-row td {
    word-break: break-word;
}
/* Styling for the action buttons */
.actions {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.edit-button, .delete-button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.edit-button {
    background-color: #4CAF50;
    color: white;
}

.delete-button {
    background-color: #f44336;
    color: white;
}

.edit-button:hover {
    background-color: #45a049;
}

.delete-button:hover {
    background-color: #d32f2f;
}

/* Styling for checkboxes */
input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

/* Adjust table layout for checkboxes */
.department-table th:first-child,
.department-table td:first-child {
    width: 40px;
    text-align: center;
}
