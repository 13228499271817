/* LandingPage.css */
.ATS-landing-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  background-size: cover;
  background-position: center;
  color: var(--fullBlack);
}

  
  .ATS-landing-content {
    max-width: 50%;
  }
  
  .ATS-landing-content h1 {
    font-size: 3.5rem;
    font-weight: bolder;
    line-height: 1.2;
    word-wrap: break-word;
}
  
  .ATS-landing-content p {
    font-size: 1.2rem;
    margin: 20px 0;
  }
  
  .ATS-landing-btns {
    display: flex;
    gap: 15px;
  }
  
  .ATS-landing-signup {
    padding: 10px 30px;
    font-size: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--headerBackground);
    color:var(--fullWhite);
    font-weight: 800;
  }
  .ATS-landing-signup:hover {
    background-color: var(--fullWhite);
    color:var(--headerBackground);
    border-radius: 10px;
    border: 1px solid var(--headerBackground);
    
  }
  .ATS-landing-btn{
    background-color: transparent;
    border: 2px solid var(--buttonBlue);
    border-radius: 10px;
    padding: 10px 30px;
    cursor: pointer;
    color: var(--fullBlack);
    font-weight:bold;
  }
  .ATS-landing-btn:hover{
    border: 2px solid var(--buttonBlue);
    background-color: var(--buttonBlue);
    border-radius: 10px;
    padding: 10px 30px;
    cursor: pointer;
    color: var(--fullWhite);
    font-weight:bold;
  }

  
  .btn-secondary {
    background-color: transparent;
    color: #0033ff;
    border: 2px solid #0033ff;
  }
  
  .illustration img {
    max-width: 400px;
  }
  @media (max-width: 1024px) {
    .ATS-landing-container {
      flex-direction: column; /* Stack items vertically */
      text-align: center; /* Center text */
      padding: 40px;
    }
  
    .ATS-landing-content {
      max-width: 80%;
    }
  
    .ATS-landing-content h1 {
      font-size: 3rem;
    }
  
    .ATS-landing-btns {
      justify-content: center;
    }
  
    .illustration img {
      max-width: 350px;
    }
  }
  
  /* Mobile Devices (max-width: 768px) */
  @media (max-width: 768px) {
    .ATS-landing-container {
      flex-direction: column;
      text-align: center;
      padding: 30px;
    }
  
    .ATS-landing-content {
      max-width: 100%;
    }
  
    .ATS-landing-content h1 {
      font-size: 2.5rem;
    }
  
    .ATS-landing-content p {
      font-size: 1rem;
    }
  
    .ATS-landing-btns {
      flex-direction: row;
      gap: 10px;
      align-items: center;
    }
  
    .ATS-landing-signup,
    .ATS-landing-btn {
      width: 100%;
      padding: 12px 0;
      font-size: 1rem;
    }
  
    .illustration img {
      margin-top: 2rem;
      max-width: 300px;
    }
  }
  
  /* Small Mobile Devices (max-width: 480px) */
  @media (max-width: 480px) {
    .ATS-landing-container {
      padding: 20px;
    }
  
    .ATS-landing-content h1 {
      font-size: 2rem;
    }
  
    .ATS-landing-content p {
      font-size: 0.9rem;
    }
  
    .ATS-landing-signup,
    .ATS-landing-btn {
      font-size: 0.9rem;
    }
  
    .illustration img {
      display: none;
    }
  }
