.company-details-container {
    background: #fff;
    width: 100%;
}

/* Tabs */
.setting-company-tabs {
    display: flex;
    gap: 15px;
    /* margin-bottom: 20px; */
    border-bottom: 1px solid #EDEDED;
    /* padding-bottom: 5px; */
}

.setting-company-tabs button {
    padding: 12px 18px;
    border: none;
    background: transparent;
    color: #00000080;;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px 5px 0 0;
    transition: background 0.3s ease;
}

.setting-company-tabs button.active {
    color: black;
    border-bottom: 2px solid #1E88E5;
}

/* Form Section */
.general-details-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Aligns input fields properly in 2 columns */
.company-detail-form-group {
    display: flex;
    /* justify-content: space-between; */
    gap: 2%;
    margin-bottom: 15px;
}

/* Adjusts input group to align within two columns */
.comapany-input-group {
    display: flex;
    flex-direction: column;
    /* gap: 2%; */
    width: 40%;
}
.mobile-input {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
  }
  
  .mobile-input select {
    width: 5%;
    min-width: 50px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .mobile-input input {
    width: 120%;
    padding: 5px;
    font-family: 'poppins';
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  

.comapany-input-group label {
    font-weight: 500;
    margin-bottom: 5px;
    line-height: 24px;
    font-size: 14px;
    color: #7E89A5;
}

.comapany-input-group input,
textarea {
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    font-family: 'poppins';
    font-size: 14px;
    padding: 8px;
}

/* Textarea */
.setting-description {
    width: 84%;
}

.setting-description textarea {
    height: 120px;
    resize: none;
}

/* Action Buttons */
.company-form-actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    gap: 10px;
}
.company-setting-edit{
    margin-left: 1%;
    color:#1E88E5;
    font-size: 20px;
    background-color: transparent;
  }
.company-cancel-btn {
    background: white;
    color: black;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #00000033;
    cursor: pointer;
    font-size: 14px;
}

.company-save-btn {
    background: #1E88E5;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 14px;
}
.branding-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.branding-upload-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.upload-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.upload-box label {
    font-weight: 500;
    line-height: 24px;
    color: #7E89A5;
    font-size: 14px;
}

.drop-area {
    border: 2px dashed #ccc;
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    background: #f9f9f9;
    cursor: pointer;
    transition: background 0.3s ease;
}

.drop-area:hover {
    background: #f0f0f0;
}

.drop-area p {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
}

.drop-area span {
    font-size: 12px;
    color: #888;
}

/* Branding Buttons */
.branding-form-actions {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.branding-cancel-btn {
    background: white;
    color: black;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #00000033;
    cursor: pointer;
    font-size: 14px;
}

.branding-save-btn {
    background: blue;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 14px;
}
.locale-info-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.locale-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.locale-field {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.locale-field label {
    font-weight: 500;
    line-height: 24px;
    font-size: 14px;
    color: #7E89A5;
}

.locale-field select,
.locale-field input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    background: #f9f9f9;
}

.locale-time-format {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.time-format-options {
    display: flex;
    gap: 15px;
}

.time-format-options label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.locale-form-actions {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 20px;
}

.locale-cancel-btn {
    background: wh;
    color: black;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #00000033;
    cursor: pointer;
    font-size: 14px;
}

.locale-save-btn {
    background: blue;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 14px;
}
