.tree-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
}

.tree-level {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px; /* More spacing between nodes */
  margin-top: 15px;
  position: relative;
}

.tree-node {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 220px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

/* Sub-tree positioning */
.tree-node > .sub-tree {
  margin-top: 50px; /* Increased margin for better spacing */
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

/* Create spacing between parent and child nodes */
.tree-node.expanded {
  background-color: red;
  margin-bottom: 50px; /* More space for connection clarity */
}

/* Node Content Styling */
.node-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.node-name {
  font-size: 14px;
  font-weight: bold;
}

.node-role {
  font-size: 12px;
  color: gray;
}

/* Expand Button Styling */
.expand-btn {
  display: inline-block;
  background: #007bff;
  color: white;
  font-size: 14px;
  font-weight: bold;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}

.expand-btn:hover {
  background: #0056b3;
}

/* Connection Line Styling */
.tree-node::before,
.tree-node::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  background: #ccc;
}

.tree-node::before {
  top: -65px; /* Extends upwards to connect to parent */
  height: 30px;
}

.tree-node:first-child::before {
  display: none; /* Hide top line for the root node */
}

/* Horizontal Line Between Siblings */
.tree-level::before {
  content: "";
  position: absolute;
  top: -38px; /* Controls the positioning of the horizontal connector */
  left: 0;
  width: 100%;
  height: 2px;
  background: #ccc;
}

/* Ensure proper alignment for horizontal connections */
.tree-level .tree-node::after {
  top: -15px;
  width: 50%;
  height: 2px;
  display: none;
}

.tree-level .tree-node:first-child::after {
  left: 50%;
}

.tree-level .tree-node:last-child::after {
  width: 50%;
}
