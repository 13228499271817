/* Container Styling */
.steps-container {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Step Cards - Speech Bubble Style */
.steps {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 80%;
  max-width: 900px;
  margin: 30px 0;
}

.step-card {
  background: #007bff;
  color: white;
  border-radius: 12px;
  width: 350px !important; /* Increased width */
  height: 180px; /* Adjusted height */
  padding: 20px;
  text-align: left;
  position: relative;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.step-card.active {
  opacity: 1;
  transform: scale(1);
}

/* Triangle Pointer for Speech Bubble */
.step-card::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 30px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #007bff;
}

/* Step Header */
.step-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.step-icon {
  width: 40px;
  height: 40px;
}

.step-number {
  font-size: 16px;
  font-weight: bold;
}

/* Progress Bar */
.progress-bar {
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 900px;
  position: relative;
  align-items: center;
  margin-top: 30px;
}

.progress-step {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  position: relative;
  flex: 1;
  opacity: 0.5;
  transform: scale(0.8);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  margin-left: 10px;
}

.progress-step.filled {
  opacity: 1;
  transform: scale(1);
}

/* Step Circle */
.step-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #007bff;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-left: 0px;
}

/* Animated Progress Bar */
.progress-step::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 4px;
  background: #ccc;
  transform: translateY(-50%);
  transition: width 1s ease-in-out;
  z-index: 1;
}

.progress-step.filled::after {
  background: #007bff;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .steps,
  .progress-bar {
    flex-direction: column;
    align-items: center;
  }

  .progress-step:not(:last-child)::after {
    width: 50%;
    left: 25%;
  }

  .step-card {
    width: 90%;
  }
}
