.ats-contactUs-container {
  display: flex;
  justify-content: center;  
  align-items: start;
  flex-wrap: wrap;
  margin: 40px auto;
  padding: 20px;
  background: white;
  background-size: cover;
  background-image: url('../../Assests/AtsContactUsbackground.png.png');
  gap: 20px;
  
  
  }
  
  
  .ats-contactUs-left {
  flex: 1;
  padding: 0px 60px;
  min-width: 300px;
  max-width: 600px;
  text-align: left;
  }
  
  
  .ats-contactUs-heading {
  font-weight: bold;
  font-size: 2rem;
  text-align: left;
  }
  
  
  .ats-contactUs-highlight{
  color:#008cff;
  }
  .ats-contactUs-text {
  color: black;
  margin-bottom: 15px;
  font-size: 1.2rem;
  text-align: left;  
}

.ats-contactUs-info {
  text-align: left; 
}

.ats-contactUs-email
 {
  margin-bottom: 8px;
  font-size: 1rem;
  }
  
  .ats-contactUs-social {
  margin-top: 15px;
  text-align: left;
  }
  
  .ats-contactUs-icons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
margin-left: 2rem;
  }
  
  .ats-contactUs-icon {
  width: 24px;
  height: 24px;
  }
  
  
  .ats-contactUs-form {
  width: 100%;
  max-width: 500px;
  padding: 40px;
  border: 1px solid #008cff;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 140, 255, 0.3);
  margin-right: 50px;

}

.ats-contactUs-label {
  display: block;
  font-weight: bold;
  margin-top: 10px;
  color: #333;
  padding: 10px 0px;
}


.ats-contactUs-input, .ats-contactUs-textarea {
  width: 90%;
  padding: 15px 10px;
  margin-top: 5px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 14px;
  background-color: white;
  }
  
  
  .ats-contactUs-textarea {
  height: 100px;
  resize: none;
  }
  
  
  .ats-contactUs-radioGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 5px;
  }
  
  .ats-contactUs-radioLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  }
  
  
  .ats-contactUs-button {
  width: 30%;
  padding: 12px;
  background: #00BCD4;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  transition: background 0.3s ease-in-out;
}
.error-text{
  color: red;
  font-size: small;
}
.ats-contactUs-input:focus,
.ats-contactUs-textarea:focus {
  outline: none;
  border: 1px solid black;
}
.contactus-icon-image{
  margin-right: 0.6rem;
}

@media (max-width: 768px) {
  .ats-contactUs-left {
      padding: 0px 20px;
      text-align: center;
  }
  .ats-contactUs-button {
    width: 50%;
    font-size: 16px;
  }
}


@media (max-width: 767px) {
  .ats-contactUs-container {
    padding: 30px;
  }
  .ats-contactUs-left {
    padding: 0px 20px;
    text-align: center;
  }
  .ats-contactUs-heading {
      font-size: 1.8rem;
  }
  .ats-contactUs-text {
      font-size: 0.9rem;
  
  }


  .ats-contactUs-form {
      width: 90%;
      padding: 30px;
  }
  .ats-contactUs-input,
  .ats-contactUs-textarea {
      font-size: 14px;
      padding: 12px 8px;
  }
  .ats-contactUs-button {
      width: 100%;
      font-size: 15px;
  }
  }
  
  
  @media (max-width: 480px) {
  .ats-contactUs-container {
      margin-top: 0;
      /* background-color: blue; */
      padding: 20px;
  }
  .ats-contactUs-left {
      padding: 0px 15px;
      text-align: center;
  }
  .ats-contactUs-heading {
      font-size: 1.5rem;
  }
  .ats-contactUs-text {
      font-size: 0.9rem;
  }
  .ats-contactUs-form {
      width: 100%;
      padding: 20px;
  }
  .ats-contactUs-input,
  .ats-contactUs-textarea {
      font-size: 13px;
      padding: 10px 8px;
  }
  .ats-contactUs-button {
      width: 100%;
      font-size: 14px;
  }
  }
  