.hero-container {
  text-align: center;
  /* background-color: red; */
  padding-bottom: 0;
  margin-bottom: 0;
  overflow: hidden;
}
  
  .highlight {
    color: #007bff;
    font-weight: bold;
  }
  
  /* .buttons {
    margin: 20px 0;
  }

  .elips-get-started{
    background-color: transparent;
    color: white;
    border:none;
    padding: 10px;
    border: 1px solid #1e293b;
  }
.elips-see-demo{
    background-color: transparent;
    color: #1e293b;
    border: 2px solid #1e293b;

} */

.ellips-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
    /* background-color: blue; */
  }

  .elips-get-started {
    background-color: #1e293b;
    color: white;
    border: 2px solid #1e293b;
    padding: 10px 26px;
    font-size: 16px;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .elips-get-started:hover {
    background-color: transparent;
    color: #1e293b;
  }
  
 
  .elips-see-demo {
    background-color: transparent;
    color:#1e293b;
    border: 2px solid #00bcd4;
    padding: 10px 28px;
    font-size: 16px;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  

  .elips-see-demo:hover {
    background-color: #00bcd4;
    color: white;
    border: 2px solid #00bcd4;
    
  }
  

  .ellipse-container {
    position: relative;
    width: 80%;
    height: 350px;
    margin: 50px auto;
    /* background-color: blue; */
  }
  

  .ellipse-bg {
    width: 100%;
    height: auto;
    display: block;
  }
  

  .ats-logo {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
  }
  

  .icon {
    position: absolute;
    width: 50px;
    animation: floatY 3s infinite ease-in-out;
  }
  
  @keyframes floatY {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
  }


  /* Adjusted icon positions along the elliptical path */
  .icon1 { top: 20%; left: 30%;animation-delay: 0s; }  
  .icon2 { top: 39%; right: 38%; animation-delay: 0.3s;} 
  .icon3 { top: 30%; left: 15%; animation-delay: 0.6s;}   
  .icon4 { bottom: 28%; right: 66%;animation-delay: 0.9ss; } 
  .icon5 { bottom:48%; left: 95%; animation-delay: 1.3s;}
  .icon6 { top: 18%; right: 28%; animation-delay: 1.6s;} 
  .icon7 { top: 55%; right: 25%; animation-delay: 1.6s;} 
  
  /* Large Screens & 4K Displays (1440px - 3000px) */
@media (min-width: 1440px) {
  .hero-container {
    padding: 80px 40px;
  }
  .ellipse-container {
    width: 70%;
    height: 450px;
  }
  .ats-logo {
    width: 120px;
  }
  .icon {
    width: 70px;
  }
  .elips-get-started,
  .elips-see-demo {
    padding: 12px 32px;
    font-size: 18px;
  }
}

/* Laptops & Medium Screens (1024px - 1439px) */
@media (max-width: 1439px) {
  .hero-container {
    padding: 60px 30px;
  }
  .ellipse-container {
    width: 75%;
    height: 400px;
  }
  .ats-logo {
    width: 100px;
  }
  .icon {
    width: 60px;
  }
}

/* Tablets & Small Laptops (768px - 1023px) */
@media (max-width: 1023px) {
  .hero-container {
    padding: 50px 20px;
  }
  .ellipse-container {
    width: 80%;
    height: 350px;
  }
  .ats-logo {
    width: 90px;
  }
  .icon {
    width: 50px;
  }
  .ellips-buttons {
    flex-direction: column;
    gap: 10px;
  }
  .elips-get-started,
  .elips-see-demo {
    width: 100%;
  }
}

/* Large Phones & Small Tablets (480px - 767px) */
@media (max-width: 767px) {
  .hero-container {
    padding: 40px 15px;
  }
  .ellipse-container {
    width: 90%;
    height: 320px;
  }
  .ats-logo {
    width: 80px;
  }
  .icon {
    width: 40px;
  }
  .elips-get-started,
  .elips-see-demo {
    width: 100%;
  }
  .icon1 { top: 20%; left: 25%; } 
  .icon2 { top: 20%; right: 25%; } 
  .icon3 { top: 50%; left: 10%; }  
  .icon4 { bottom: 30%; right: 50%; } 
  .icon5 { bottom: 45%; left: 80%; }  
  .icon6 { top: 60%; right: 15%; } 
  .icon7 { top: 65%; right: 35%; } 
}

/* Small Phones (up to 479px) */
@media (max-width: 479px) {
  /* .hero-container {
    padding: 30px 10px;
  } */
  .ellipse-container {
    width: 100%;
    height: auto;

  }
  .ellipse-bg{
    margin-top: 15%;
  }
  .ats-logo {
    width: 70px;
  }
  .icon {
    width: 35px;
  }
  .ellips-buttons {
    flex-direction: column;
    width: 100%;
  }
  .elips-get-started,
  .elips-see-demo {
    width: 100%;
    font-size: 14px;
  }
  .icon1 { top: 15%; left: 20%; } 
  .icon2 { top: 85%; right: 40%; } 
  .icon3 { top: 65%; left: 5%; }  
  .icon4 { bottom: 5%; right: 60%; } 
  .icon5 { bottom: 5%; left: 60%; }  
  .icon6 { top: 65%; right: 10%; } 
  .icon7 { top: 25%; right: 30%; } 
}
