.features-main-container {
    /* background-color: #00BCD4; */
    padding: 30px 0px;
    background:
        linear-gradient(180deg, #1E88E5, #1E88E5, #114B7F),
        url('../../Assests/Grains.png');
    background-blend-mode: overlay;
    background-size: cover;
    background-blend-mode: overlay;
  
  
    /* margin: auto; */

 
  
}


.feature-heading {
    color: white;
    text-align: center;
    margin: 30px 0px;
}
.feature-button-container{
    max-width: 1120px;
  margin: 10px auto;
  padding: 0 20px;
}
.features-button {
    background-color: white;
    margin: 10px 0;
    border-radius: 8px;
    padding: 10px 0px;
    border: none;
    width: 150px;
    color: #3b82f6;
    font-weight: 600;

}

.feature-icon {
    padding: 0px 10px;
}

.side-side-sections {
    display: flex;
    
    max-width: 1120px;
    gap: 10px;
    margin: auto;
    margin-bottom: 0.6rem;
   
}

/* Ai Resume styles start */
.aiResume-screening-container {
    width: 420px;
    height: 280px;
    border: 1px solid #E2E2E2;
    background-color: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 8px 0px; */
    transition: box-shadow 0.3s ease-in-out;
    overflow: hidden;

}

.aiResume-screening-container:hover {
    border-color: #00BCD4;
}

.ai-resume-image-container {
    width: 100%;
    height: 55%;
    position: relative;
    text-align: end;
    /* right: -30px; */
    overflow: hidden;
    background: linear-gradient(180deg, #00BCD4 , #00BCD4);
    

}

.ai-message-image {
    width: 250px;
    transform: translate(-50px, -175px);
    transition: transform 0.5s ease-in-out;


}

.ai-stars {
    transform: translate(-360px, -200px);
    transition: transform 0.5s ease-in-out;
}

.aiResume-screening-container:hover .ai-message-image {
    transform: translate(-70px, -175px);
}

.aiResume-screening-container:hover .ai-stars {
    transform: translate(-370px, -170px);
}

/* AI Resume styles End */

/* role-based dashboard styles start */
.role-based-dashboard-container {
    width: 350px;
    height: 280px;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 0px 0px;
    transition: box-shadow 0.3s ease-in-out;
    
}

.role-based-dashboard-container:hover {
    border-color: #3b82f6;
}

.dashboard-image-container {
    width: 100%;
    height: 55%;
    border-radius: 5px;
    background: linear-gradient(180deg, rgba(76, 175, 80, 0.3) 0%, rgba(255, 255, 255, 1) 100%);

}

.dashboard-board {
    transform: translate(85px, -40px);
    transition: transform 0.3s ease-in-out;
}

.role-based-dashboard-container:hover .dashboard-board {
    transform: translate(85px, -50px);
}

.dashboard-role1 {
    transform: translate(70px, -170px);
  
    transition: transform 0.3s ease-in-out;
}

.role-based-dashboard-container:hover .dashboard-role1 {
    transform: translate(40px, -150px);
}

.dashboard-role2 {
    transform: translate(190px, -90px);
    transition: transform 0.3s ease-in-out;
}

.role-based-dashboard-container:hover .dashboard-role2 {
    transform: translate(110px, -115px);
}

.dashboard-role3 {
    transform: translate(-105px, -160px);
    transition: transform 0.3s ease-in-out;
}

.role-based-dashboard-container:hover .dashboard-role3 {
    transform: translate(-85px, -180px);
}

/* role based dashboard styles ends */





/* fetures-job-posting section styles start */
.feature-jobPosting-container {
    width: 350px;
    height: 250px;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px;
    transition: box-shadow 0.3s ease-in-out;
}

.feature-jobPosting-container:hover {
    border-color: #00BCD4;
}

.image-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fea-job-post {
    width: 90%;
    height: auto;
}

.arrow-icon {
    position: absolute;
    transform: translate(40px, 80px);
    transition: transform 0.5s ease-in-out;
}

.feature-jobPosting-container:hover .arrow-icon {
    transform: translate(0px, 45px);
}

.text-section {
    width: 100%;
    text-align: start;
    margin-top: 10px;
    background-color: white;
}

.title {
    padding-left: 4%;
    font-size: 16px;
    font-weight: 600;
    color: #111827;
}

.description {

    font-size: 14px;
    color: #6B7280;
    padding-left: 4%;
    margin: 0px;
    max-width: 100%;


}

/* fetures-job-posting section styles end */


/* calender-styles start */

.hover-card {
    width: 310px;
    height: 270px;
    padding: 16px;
    background-color: #ffff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    background-image: url('../../Assests/calenderbg.svg');
}

.hover-card:hover {
    border-color: #3b82f6;
}

.hover-card img {
    width: 80%;
    height: auto;
    padding-left: 30px;
    transition: opacity 0.3s ease-in-out;
}

.message-box {
    position: absolute;

    max-width: 60px;
    height: 5px;
    top: 60px;
    translate: 40px 0 0;
    z-index: 2;
}


.hover-card:hover .message-box {
    transition: all 0.3s ease-in-out;
    transform: scale(0.85) translate(10px, -10px) rotate(-20deg);
    opacity: 0.9;

}

/* calender styles end  */


/* Notification styles start */
.notification-container {
    width: 350px;
    height: 270px;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px;
    transition: box-shadow 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
}

.notification-container:hover {
    border-color: #3b82f6;
}

.notification-image-container {
    width: 100%;
    position: relative;
    height: 60%;
    text-align: center;
    overflow: hidden;
    background-image: url('../../Assests/Notificationbg.svg');
    background-size: cover;
    background-position: center;
}

.notification-main {
    z-index: 7;
    position: relative;

}

.notification-shimmer {
    transform: translate(0px, 25px);
    transition: all 0.2s ease-in-out;

}

.notification-shimmer-down {
    transform: translate(0px, -35px);
    transition: all 0.2s ease-in-out;
}

.notification-container:hover .notification-shimmer {
    transform: translate(0px, 12px);
}

.notification-container:hover .notification-shimmer-down {
    transform: translate(0px, -24px);
}

/* Notification styles End  */

/* Advanced Analytics Styles start */
.Analytics-card {
    width: 400px;
    height: 270px;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    background-color: #FFFFFF;

}

.Analytics-card:hover {
    border-color: #00BCD4;

}

.chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 15px;
    background: #f9f9f9;
    margin-bottom: 16px;
    margin: 30px 90px;


}

.chart-bg {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    padding: 20px 10px;
    border-radius: 15px;
    border: 1px solid #E2E2E2;
    background-color: white;
}

.chart {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    height: 100px;
    width: 100%;


}

.bar-wrapper {
    width: 25px;
    height: 100%;
    background-color: #E9ECF1D1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 4px;


}

.bar {
    width: 23px;
    border-radius: 4px;
    transition: height 0.3s ease-in-out;

}

.dark {
    background-color: #2C3E50;
}

.green {
    background-color: #4CAF50;
}

/* Advanced Analytics styles ends */

@media (max-width:768px) {
    .side-side-sections{
        display: flex;
        flex-direction: column;
        margin: 10px 0px;
    }
   
.features-main-container{
    padding: 0;
   max-width: 100vw;
}
.Analytics-card{
    width: 320px;
    margin: auto;
}
.hover-card{
    width:320px;
    margin: auto
}
.aiResume-screening-container{
    width: 340px;
    margin: auto;
}
.ai-message-image {
    transform: translate(-40px, -175px);
    transition: transform 0.5s ease-in-out;


}

.role-based-dashboard-container{
    margin: auto;
}
.feature-jobPosting-container{
    margin: auto;
}
.notification-container{
    margin: auto;
}

}

