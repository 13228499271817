.atsdashboardheader-wrapper {
  position: fixed;
  margin-top: 3.4vh;
  top: 6vh; 
  width: 100%;
  z-index: 99999; 
  background: #f7f7f7;
  box-shadow: 0px 4px 6px rgba(58, 53, 53, 0.1);
}

.atsdashboardheader-container {
  display: flex;
  align-items: center;
  justify-content: start;
  background: #f8f9fc;
  padding: 6px 20px 0px 20px;
  height: 7vh;
  width: 100%;

 
}

.atsdashboardheader-icon-menu {
  display: flex;
  justify-content:start;
  padding: 0;
 
}



.atsdashboardheader-icon-button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
 
  border-right: 0.5px solid #ddd;
  height: 7vh;
}

.atsdashboardheader-icon-button:hover {
  background-color: white;
}

.atsdashboardheader-icon-wrapper.active::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 4px;
  background-color: #4a85a8;
  /* border-radius: 2px; */
}

.atsdashboardheader-icon-button.active {
  background: white;
}

.atsdashboardheader-icon-wrapper {
  position: relative;
  flex-grow: 1;
  text-align: center;
}

.atsdashboardheader-tooltip {
  visibility: hidden;
  color: #130101;
  text-align: center;
  /* background-color: red; */
  font-size: 13px;
  padding: 5px 10px;
  /* border-radius: 4px; */
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 10;
  font-size: 13PX;
  color: black;
  background-color: white;
  border: 0.5px solid #A7D1FF;
  margin-top: 0.5rem;
}

.atsdashboardheader-icon-wrapper:hover .atsdashboardheader-tooltip {
  visibility: visible;
  opacity: 1;
 

}

.atsdashboardheader-menu-icon {
  width: 20px;
  height: 20px;
  padding: 0 5px;
}

.menu-label {
  display: none;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

@media (min-width: 1600px) {
  .menu-label {
    display: inline-block;
    margin-left: 5px;
  }
  .atsdashboardheader-tooltip{
    visibility: hidden;
  }
}

@media (max-width: 480px) {
  .atsdashboardheader-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .atsdashboardheader-icon-menu {
    gap: 3px;
  }

  .atsdashboardheader-icon-wrapper {
    flex: 1 1 20%;
    max-width: 60px;
    padding: 3px 0;
  }

  .atsdashboardheader-icon-button {
    height: auto;
    min-width: 50px;
    padding: 8px;
  }

  .atsdashboardheader-menu-icon {
    width: 18px;
    padding: 0;
  }
}
