.accordion {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    overflow: hidden;
    background: #fff;
  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 1.5rem;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    background: #f9fafb;
    border-bottom: 1px solid #ddd;

  }
  
  .accordion-header:hover {
    background: #f6faff;
  }
  
  .accordion-icon {
    transition: transform 0.3s ease;
  }
  
  .accordion.open .accordion-icon {
    transform: rotate(180deg);
  }
  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    padding: 0;
    
  }
  
  .accordion.open .accordion-content {
    max-height:fit-content;
    
  }